<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none pt-3" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'Account Settings' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <!-- <user-settings-general /> -->
        <user-settings-info v-if="!isPartner"/>
        <PartnerSettingsInfo v-else/>
      </div>
    </vs-tab>
  <!--  <vs-tab v-if="loginType === 'email'" icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Change Password' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-change-password />
      </div>
    </vs-tab>  --->

   

    <!-- v-if="isPartner" -->
    <!--
     <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? 'Payment Methods' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
       <payment-method />
      </div>
    </vs-tab> 
    
   <vs-tab  icon-pack="feather" icon="icon-dollar-sign" :label="!isSmallerScreen ? 'Payout Account' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
       <payout-method />
      </div>
    </vs-tab> 
    -->
  <!--   <vs-tab icon-pack="feather" icon="icon-file-text" :label="!isSmallerScreen ? 'Receipts' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
       <invoices />
      </div>  
    </vs-tab> --->
    <vs-tab icon-pack="feather" icon="icon-link-2" :label="!isSmallerScreen ? 'Social Links' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
       <user-settings-social-links />
      </div>
    </vs-tab>


  </vs-tabs>
</template>

<script>

import UserChannels from './UserChannels.vue'
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'

import UserSettingsInfo from './UserSettingsInfo.vue'
import PartnerSettingsInfo from './PartnerSettingsInfo'

import UserSettingsSocialLinks from './UserSettingsSocialLinks.vue'
import UserSettingsConnections from './UserSettingsConnections.vue'


import Invoices from './Invoices.vue'

export default {
  name: 'UserSettings',
  components: {
    UserChannels,
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserSettingsInfo,
    PartnerSettingsInfo,
    UserSettingsSocialLinks,


    Invoices
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
    loginType () {
      return this.$store.state.auth.userData.loginType
    }
  },
 mounted () {
  this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  }, 
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
