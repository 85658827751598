<template>
  <vx-card title="Social Media Links" no-shadow>
    <div class="vx-row"> 
      <div class="vx-col sm:w-1/2 w-full">
          <vs-input class="w-full mb-5" v-model="facebook" icon-pack="feather" icon="icon-facebook" label="Facebook" icon-no-border />
        </div>
       <div class="vx-col sm:w-1/2 w-full">
          <vs-input class="w-full mb-5" v-model="twitter" icon-pack="feather" icon="icon-twitter" label="Twitter" icon-no-border />
       </div>
       <div class="vx-col sm:w-1/2 w-full">
          <vs-input class="w-full mb-5" v-model="linkedin" icon-pack="feather" icon="icon-linkedin" label="Linkedin" icon-no-border />
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <vs-input class="w-full mb-5" v-model="instagram" icon-pack="feather" icon="icon-instagram" label="Instagram" icon-no-border />
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <vs-input class="w-full mb-5" v-model="web" icon-pack="feather" icon="icon-globe" label="Web" icon-no-border />
        </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" size="small" @click="submit" >Save Changes</vs-button>
      <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click='backToHomePage'>Close</vs-button>
    </div>
  </vx-card>
</template>

<script>

import {
    updateUser
} from '@/graphql/mutations';
import {
    API,
    graphqlOperation
} from '@aws-amplify/api';
import { createErrorObject, createSuccessObject } from '@/utils'

export default {
  data () {
    return {
      twitter: this.$store.state.auth.userData.twitterLink,
      facebook: this.$store.state.auth.userData.facebookLink,
      instagram: this.$store.state.auth.userData.instagramLink,
      linkedin: this.$store.state.auth.userData.linkedinLink,
      web: this.$store.state.auth.userData.webLink,
      name: this.$store.state.auth.userData.name,
      email: this.$store.state.auth.userData.email,
    }
  },
  computed: {

  },
  methods: {
    backToHomePage () {
      this.$router.push('/')
    },
    async submit () {
        const updateRes = await API.graphql(graphqlOperation(updateUser, {
          input: {
            name: this.$store.state.auth.userData.name,
            twitterLink: this.twitter,
            facebookLink:  this.facebook,
            instagramLink:  this.instagram,
            linkedinLink:  this.linkedin,
            webLink:  this.web,
          }
        }))

        this.$store.commit('auth/UPDATE_USER_INFO', updateRes.data.updateUser)
        this.$vs.notify(createSuccessObject('Success', 'Update profile succeeded!'))        
    }
  }
}
</script>
