<template>
 <div class="items-grid-view vx-row match-height" v-if="channelList.length" appear>
        <div class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full" v-for="item in channelList" :key="item.objectID">
            <item-grid-view :item="item">
                <!-- SLOT: ACTION BUTTONS -->
                <template slot="action-buttons">
                    <div class="flex flex-wrap">
                        <div class="item-view-primary-action-btn p-3 flex flex-grow items-center justify-center cursor-pointer" v-if="item.inCart == '1'" @click.prevent="Removefromcart(item)">
                            <feather-icon icon="XIcon" svgClasses="h-4 w-4" />

                            <span class="text-sm font-semibold ml-2">REMOVE</span>
                        </div>
                        <div class="item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer" @click.prevent="AddCart(item)" v-else>
                            <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4" />

                            <span class="text-sm font-semibold ml-2">SELECT CHANNEL</span>
                        </div>
                    </div>
                </template>
            </item-grid-view>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios'
const ItemGridView = () => import('./components/ItemGridView.vue')
export default {
  data () {
    return {
      email: this.$store.state.auth.userData.email,
      channelList : [],
      selected_channels : []
    }
  },
  created () {
    this.getAllProfileDetailsBasedOnEmail()
  },
  components: {
    ItemGridView
  },
  methods : {
    getAllProfileDetailsBasedOnEmail () {
      this.user_data = {}
      axios.post(`${process.env.VUE_APP_BASE_URL  }/api/users/getAllProfileDetailsBasedOnEmail`, {
        email_id : this.email
      })
        .then(res => {          
          if (res.data.status === true) {
            this.user_data = res.data.data[0]
            const channels = this.user_data.channels.split(',')
            for (let j = 0; j < channels.length; j++) {
              const element = channels[j]
              this.selected_channels.push(element)        
            }
            this.getAllChannelsOfUser()
          }
        })
        .catch(err => this.$store.commit('SET_ERROR', err, { root: true }))
    },    
    getAllChannelsOfUser () {
      this.channelList = []
      axios.post(`${process.env.VUE_APP_BASE_URL  }/api/getAllChannelsOfUserByEmail`, {
        email_id : this.email
      }).then(res => {          
        if (res.data.status === true) {
          const data = res.data.data
          for (let index = 0; index < data.length; index++) {
            const element = data[index]            
            const record = {
              id: element.channel_id,
              name: element.channel_name,
              description: element.channel_excerpt,
              image: element.channel_image_url,
              inCart: false
            }
            for (let j = 0; j < this.selected_channels.length; j++) {
              const channel = this.selected_channels[j]
              if (parseInt(channel) === parseInt(element.channel_id)) {
                record.inCart = true
              }                
            }
            this.channelList.push(record)          
          }
        }
      })
        .catch(err => this.$store.commit('SET_ERROR', err, { root: true }))
    }
  }

}

</script>