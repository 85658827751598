<template>
<div class="">
    <vx-card no-shadow>
        <div class="invoices">
            <h4 class="mb-4">Payment Receipts</h4>

            <div class="">
                <vs-table stripe :data="order_data">
                    <template slot="thead">
                       
                        <vs-th>Item name</vs-th>
                        <vs-th>Item type</vs-th>
                        <vs-th>Link</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                            

                            <vs-td :data="data[indextr].title">
                                {{ data[indextr].title }}
                            </vs-td>

                            <vs-td>
                             
                              
                                <vs-chip :data="data[indextr].type" class='text-primary'>{{ data[indextr].type }}</vs-chip>
                              
                                
                            </vs-td>
                            <!-- <vs-td :data="data[indextr].price">
                                {{ data[indextr].price }}
                            </vs-td> -->

                            <vs-td>
                                <vs-button @click="chargesRetrieve(data[indextr].stripeChargeId);" radius color="primary" type="filled" icon-pack="feather" icon="icon-file"></vs-button>
                            </vs-td>

                        </vs-tr>
                    </template>

                </vs-table>
            </div>
        </div>
    </vx-card>
</div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { Storage } from '@aws-amplify/storage'
import { courseFeedByUser } from '@/graphql/queries'
import { createErrorObject } from "@/utils";
import {
  courseFeedSubscriptionByUser,
  sessionBookingByUser
} from "@/graphql/queries";
export default {
    data() {
        return {
            order_data: [],
            courseFeeds:[],
            services: [],
      
      filtered: [],
        }
    },
    created () {
    this.fetchCourseFeeds()
    this.fetchSessionBookingByUser()
  },
    methods:{
       async fetchCourseFeeds () {
      try {
        const res = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
          user: this.$store.state.auth.userData.name
        }))

        for(const coursefeed of res.data.courseFeedSubscriptionByUser.items){
          let coursefeed_order={
            title:coursefeed.courseFeedRef.title,
            stripeChargeId:coursefeed.stripeChargeId,
            type:'Coursefeed'
          }
          if(coursefeed.courseFeedRef.discountPrice === null ||
            coursefeed.courseFeedRef.discountPrice === undefined ||
            coursefeed.courseFeedRef.discountPrice === 0){
            coursefeed_order.price = coursefeed.courseFeedRef.price;
          }else{
            coursefeed_order.price = coursefeed.courseFeedRef.discountPrice;
          }
          this.order_data.push(coursefeed_order)          
        }      
       
      } catch (err) {
        console.log(err)
      }
    },
    async fetchSessionBookingByUser() {
      try {
          const res = await API.graphql(graphqlOperation(sessionBookingByUser, {
            user: this.$store.state.auth.userData.name
          }))
          for(const service of res.data.sessionBookingByUser.items) {
            if(service != null){
              if(service.serviceRef != null){
                let service_order = {
                  title:service.serviceRef.title,
                  stripeChargeId:service.stripeChargeId,
                  type:'Sessions'
                }
                 if(service.serviceRef.packages.items[0].discountPrice === null ||
                  service.serviceRef.packages.items[0].discountPrice === undefined ||
                  service.serviceRef.packages.items[0].discountPrice === 0){
                  service_order.price = service.serviceRef.packages.items[0].price;
                }else{
                  service_order.price = service.serviceRef.packages.items[0].discountPrice;
                }
                this.order_data.push(service_order)
              }
            }  
          }
          this.$vs.loading.close()
      } catch (err) {
        console.log(err);
      }
    },
    async chargesRetrieve(charge_id) { 
      if(charge_id != null){
          API.post('cpRestAPI', '/stripeaccount/charges/retrieve', {
          headers: {
            'Content-Type': 'application/json'
          },
          body: {
            charge_id: charge_id,
          }}).then(res => {
            if(res.success == true){
              window.open(res.response.receipt_url,'_blank');
            }
          })
          .catch(err => {
              this.card_list = [];
          })
        }
      }, 
    }
}
</script>
