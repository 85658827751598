<template>
  <vx-card no-shadow>

    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="this.previewAvatarImage.src !== '' ?  this.previewAvatarImage.src : activeUserInfo.photoURL" size="70px" class="mr-4 mb-4" />
      <div>
        <vs-button class="mr-4 sm:mb-0 mb-2" @click="uploadImage">Upload photo</vs-button>
        <input ref="imageInputProfile" type="file" accept="image/*" @change="onImageChange"/>
        <vs-button type="border" color="danger">Remove</vs-button>
        <!-- <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p> -->
      </div>
    </div>

    <!-- Info -->
    <vs-input class="w-full mb-base" label-placeholder="Name" v-model="name" disabled></vs-input>
    <vs-input class="w-full" label-placeholder="Email" v-model="email" disabled></vs-input>

    <!-- <vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
      <span>Your account is not verified. <a href="#" class="hover:underline">Resend Confirmation</a></span>
    </vs-alert> -->

    <!-- <vs-input class="w-full my-base" label-placeholder="University" v-model="university"></vs-input> -->

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click="submit">Save Changes</vs-button>
      <vs-button class="ml-4 mt-2" type="border" color="warning">Close</vs-button>
    </div>
  </vx-card>
</template>

<script>
export default {
  data () {
    return {
      name: this.$store.state.auth.userData.name,
      email: this.$store.state.auth.userData.email,
      university: this.$store.state.auth.userData.university,
      updateAvatarImage: '',
      previewAvatarImage: { src: '' }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    }
  },
  methods: {
    uploadImage () {
      this.$refs.imageInputProfile.click()
    },
    onImageChange (e) {
      this.updateAvatarImage = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewAvatarImage) {
        return function (e) {
          previewAvatarImage.src = e.target.result
        }
      })(this.previewAvatarImage)
      fr.readAsDataURL(this.updateAvatarImage)
    },
    submit () {
      this.$store.dispatch('auth/updateUserProfile', {
        name: this.name,
        email: this.email,
        updateAvatarImage: this.updateAvatarImage,
        notify: this.$vs.notify
      })
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
</style>