import { render, staticRenderFns } from "./UserSettingsInfo.vue?vue&type=template&id=dfe57dc8&scoped=true&"
import script from "./UserSettingsInfo.vue?vue&type=script&lang=js&"
export * from "./UserSettingsInfo.vue?vue&type=script&lang=js&"
import style0 from "./UserSettingsInfo.vue?vue&type=style&index=0&id=dfe57dc8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfe57dc8",
  null
  
)

export default component.exports