<template>
  <vx-card no-shadow>
    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar
        :src="
          this.previewAvatarImage.src !== ''
            ? this.previewAvatarImage.src
            : activeUserInfo.photoURL
        "
        size="70px"
        class="mr-4 mb-4"
      />
      <div>
        <vs-button class="mr-4 sm:mb-0 mb-2" @click="uploadImage">Upload photo</vs-button>
        <input
          ref="imageInputProfile"
          type="file"
          accept="image/*"
          @change="onImageChange"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/3 w-full">
        <div class="w-full">
          <label class="text-sm">Organization Name</label>
          <vs-input 
            class="w-full"
            name="organizationName"
            v-model="organizationName"
            v-validate="{ required: true, min: 5 }"
            data-vv-validate-on="blur"
            >
            </vs-input>
            <span class="text-danger text-sm">{{
              errors.first("organizationName")
            }}</span>
        </div>
      </div>

      <div class="vx-col sm:w-1/3 w-full">
        <div class="w-full">
          <label class="text-sm">My Email Address</label>
          <vs-input 
            class="w-full"
            v-model="email" disabled></vs-input>
        </div>
      </div>

      <div class="vx-col sm:w-1/3 w-full">
        <div class="w-full">
          <label class="text-sm">Phone</label>
          <vs-input 
            class="w-full"
            type="text"
            v-validate="'numeric|min:8'"
            name="phone"
            data-vv-validate-on="blur"
            v-model="phone"></vs-input>
           <span class="text-danger text-sm">{{
              errors.first("phone")
            }}</span>
        </div>
      </div>
    </div>

    <div class="vx-row mt-4">
    <!--  <div class="vx-col sm:w-1/2 w-full">
        <div class="w-full">
          <label class="text-sm">Partner Type</label>
          <vs-select class="w-full" v-model="partnerType">
            <vs-select-item
              v-for="(item, index) in partnerTypeList"
              :key="index"
              :value="item.value"
              :text="item.label"
            />
          </vs-select>
        </div>  -->
      </div>

      <div class="vx-col sm:w-1/2 w-full" v-if="this.partnerType !=='EDUCATOR'">
        <div class="w-full">
          <label class="text-sm">Industry</label>
          <vs-input 
            class="w-full" 
            v-model="industry"
            type="text"
            name="industry"
            v-validate="'min:5'"
            data-vv-validate-on="blur"
            ></vs-input>
            <span class="text-danger text-sm">{{
              errors.first("industry")
            }}</span>
        </div>
      </div>
    </div>

    <div class="vx-row" >
      <div class="vx-col sm:w-full" v-if="this.partnerType !=='EDUCATOR'">
        <div class="w-full mt-5">
          <label class="text-sm">Services Offered</label>
          <vs-textarea
            class="w-full"
            v-model="servicesOffered"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-full" v-if="this.partnerType !=='EDUCATOR'">
        <div class="w-full">
          <label class="text-sm">Overview</label>
          <vs-textarea 
            v-model="overview" 
            class="w-full" />
        </div>
      </div>
    </div>

    <div class="vx-row mt-4">
       <div class="vx-col sm:w-1/2 w-full">
        <div class="w-full">
          <label class="text-sm">Location</label>
          <vs-input
            class="w-full"
            v-model="mainOffice"
            type="text"
            name="mainOffice"
            v-validate="'required|min:5'"
            data-vv-validate-on="blur"
          />
          <span class="text-danger text-sm">{{
            errors.first("mainOffice")
          }}</span>
        </div>
      </div>
      
      <div class="vx-col sm:w-1/2 w-full">
        <div class="w-full">
          <label class="text-sm">LinkedIn Profile</label>
          <vs-input 
            class="w-full"
            name="website"
            v-validate="{url: {require_protocol: true }}" 
            data-vv-validate-on="blur"
            v-model="website"></vs-input>
            <span class="text-danger text-sm">{{
              errors.first("website")
            }}</span>
        </div>
      </div>
    </div>

       <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-5">
      <vs-button @click="submitUpdateForm" class="ml-auto mt-2" :disabled="!isFormValid"
        >Save Changes</vs-button>
      <vs-button
        class="ml-4 mt-2"
        type="border"
        color="dark"
        @click="backToHomePage"
        >Close</vs-button>
    </div>
  </vx-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { API, graphqlOperation } from '@aws-amplify/api'
import { updatePartner } from '@/graphql/mutations'


export default {
  components: {
    flatPickr,
    vSelect
  },
  data () {
    return {
      organizationName: '',
      email: '',
      phone: '',
      partnerType: '',
      website: '',
      overview: '',
      servicesOffered: '',
      industry: '',
      mainOffice: '',
      partnerTypeList: [
        { label: 'Educator', value: 'EDUCATOR' },
        { label: 'Employer', value: 'EMPLOYER' },
        { label: 'Recruiter', value: 'RECRUITER' },
        { label: 'University', value: 'UNIVERSITY' }
      ],
      updateAvatarImage: '',
      previewAvatarImage: { src: '' }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    },
    isFormValid () {
      return !this.errors.any()
    }
  },
  created () {
    this.organizationName = this.$store.state.auth.userData.displayName
    this.email = this.$store.state.auth.userData.email
    this.phone = this.$store.state.auth.partnerData.phone
    this.partnerType = this.$store.state.auth.partnerData.partnerType
    this.website = this.$store.state.auth.partnerData.website
    this.overview = this.$store.state.auth.partnerData.overview
    this.servicesOffered = this.$store.state.auth.partnerData.specialities
    this.industry = this.$store.state.auth.partnerData.industry
    // this.mainOffice = this.$store.state.auth.partnerData.mainOffice
    this.mainOffice = this.$store.state.auth.userData.location
  },
  methods: {
    uploadImage () {
      this.$refs.imageInputProfile.click()
    },
    onImageChange (e) {
      this.updateAvatarImage = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewAvatarImage) {
        return function (e) {
          previewAvatarImage.src = e.target.result
        }
      })(this.previewAvatarImage)
      fr.readAsDataURL(this.updateAvatarImage)
    },
    submitUpdateForm () {
      // check form validity
      if (this.errors.any()) return
      this.$store.dispatch('auth/updateUserProfile', {
        displayName: this.organizationName,
        university: 0,
        universityName: '',
        studyArea: 0,
        studyAreaName: '',
        course: 0,
        courseName: '',
        about: '',
        studyStatus: '',
        email: this.email,
        updateAvatarImage: this.updateAvatarImage,
        notify: this.$vs.notify
      }).then(() => {
        this.$vs.loading()
        API.graphql(graphqlOperation(updatePartner, {
          input: {
            id: this.$store.state.auth.partnerData.id,
            phone: this.phone,
            overview: this.overview,
            website: this.website,
            partnerType: this.partnerType,
            mainOffice: this.mainOffice,
            industry: this.industry,
            specialities: this.servicesOffered
          }
        })).then(res => {
          this.$store.commit('auth/UPDATE_PARTNER_DATA', res.data.updatePartner)
          this.$vs.loading.close()
        }).catch(err => console.log(err))
      }).catch(err => console.log(err))
    },
    backToHomePage () {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
</style>
