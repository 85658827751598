<template>
  <vx-card no-shadow>
    <vs-input
      name="oldPassword"
      class="w-full mb-2"
      label="Your old password"
      placeholder="Your old password"
      type="password"
      v-model="oldPassword"
    />
    <span class="text-sm text-danger">{{ errors.oldPassword }}</span>

    <vs-input
      name="newPassword"
      class="w-full mb-2 mt-4"
      label="Enter your new password"
      placeholder="Enter your new password"
      type="password"
      v-model="newPassword"
    />
    <span class="text-sm text-danger">{{ errors.newPassword }}</span>

    <vs-input
      name="confirmPassword"
      class="w-full mb-2 mt-4"
      label="Confirm your new password"
      placeholder="Confirm your new password"
      type="password"
      v-model="confirmPassword"
    />
    <span class="text-sm text-danger">{{ errors.confirmPassword }}</span>
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click="changePassword"
        >Save Changes</vs-button
      >
      <vs-button
        class="ml-4 mt-2"
        type="border"
        color="warning"
        @click="backToHomePage"
        >Close</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },
  watch: {
    oldPassword (value) {
      if (value === '') {
        this.errors.oldPassword = 'Old Password is required'
      } else if (value.length < 6 || value.length > 32) {
        this.errors.oldPassword = 'Old Password must be between 6 and 32 characters'
      } else {
        this.errors.oldPassword = ''
      }
    },
    newPassword (value) {
      if (value === '') {
        this.errors.newPassword = 'New Password is required'
      } else if (value.length < 8 || value.length > 32) {
        this.errors.newPassword = 'New Password must be between 8 and 32 characters'
      } else if (!this.hasUpperCase(value)) {
        this.errors.newPassword = 'New Password must has at least one UPPERCASE character'
      } else if (!this.hasNumericalDigit(value)) {
        this.errors.newPassword = 'New Password must has at least one numerical digit'
      } else if (!this.hasSpecialCharacter(value)) {
        this.errors.newPassword = 'New Password must has at least one special character'
      } else if (this.hasPartOfUserName(value)) {
        this.errors.newPassword = 'New Password must not has part of username'
      } else if (value === this.oldPassword) {
        this.errors.newPassword = 'New Password cannot be the same as Old Password'
      } else {
        this.errors.newPassword = ''
      }
    },
    confirmPassword (value) {
      if (value === '') {
        this.errors.confirmPassword = 'Confirm Password is required'
      } else if (value.length < 6 || value.length > 32) {
        this.errors.confirmPassword = 'Confirm Password must be between 6 and 32 characters'
      } else if (value !== this.newPassword) {
        this.errors.confirmPassword = 'Confirm Password must be matched with New Password'
      } else {
        this.errors.confirmPassword = ''
      }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    },
    validateInput () {
      return this.errors.oldPassword === '' && this.errors.newPassword === ''  && this.errors.confirmPassword === '' 
      && this.oldPassword !== '' && this.newPassword !== '' && this.confirmPassword !== ''
    }
  },
  methods: {
    backToHomePage () {
      this.$router.push('/')
    },
    hasUpperCase (password) {
      return /[A-Z]+/.test(password)
    },
    hasNumericalDigit(password) {
      return /[0-9]+/.test(password)
    },
    hasSpecialCharacter (password) {
      return /[^a-zA-Z0-9]+/.test(password)
    },
    hasPartOfUserName (password) {
      const split = this.$store.state.auth.userData.name.split(/[-\.]/)
      for (const term of split) {
        if (password.includes(term)) {
          return true
        }
      }
      return false
    },
    changePassword () {
      // validate user input
      this.$vs.loading()
      if (this.validateInput) {
        // update user password
        axios.post(`${process.env.VUE_APP_BASE_URL  }/api/users/updateUserProfilePassword`, {
          emailid: this.$store.state.auth.userData.email,
          oldpasswrd: this.oldPassword,
          newpasswrd: this.newPassword
        })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Password change succeeded',
              text: 'Your password has updated',
              iconPack: 'feather',
              icon: 'icon-check',
              position: 'top-right',
              color: 'success'
            })
            this.$router.push('/')
          }).catch(err => {
            this.$vs.notify({
              time: 2500,
              title: 'Error',
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              position: 'top-right',
              color: 'danger'
            })
          })
      }
    }
  }
}
</script>
