<template>
  <vx-card no-shadow>
  <!-- Img Row -->
    <div class="flex flex-wrap items-center">
      <vs-avatar :src="this.previewAvatarImage.src  !== '' ?  this.previewAvatarImage.src : userData.photoURL" size="70px" class="mr-4" />
      <div>
        <vs-button class="mr-4 sm:mb-0" size="small" @click="uploadImage">Upload photo</vs-button>
        <input ref="imageInputProfile" type="file" accept="image/*" @change="onImageChange"/>
      </div>
    </div>
  <div class="vx-row">
     <div class="vx-col sm:w-1/2 w-full">
      <div class="w-full mt-5">     
          <label class="text-sm">Name</label>
         <vs-input class="w-full" label-placeholder="" v-model="displayName" ></vs-input>
      </div>                           
    </div>
    <div class="vx-col sm:w-1/2 w-full">
      <div class="w-full mt-5">
         <label class="text-sm">My Email Address</label>
         <vs-input class="w-full" label-placeholder="" v-model="email" disabled></vs-input>
     </div>                           
    </div>
  </div> 
  
<!--  <div class="vx-row">
     <div class="vx-col sm:w-1/2 w-full">
      <div class="w-full mt-5">     
          <label class="text-sm">University</label>
          <v-select v-model="university" :options="uniList" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>                           
    </div>
    <div class="vx-col sm:w-1/2 w-full">
      <div class="w-full mt-5">     
          <label class="text-sm">Study Area</label>
          <v-select v-model="studyArea" :options="studyAreaList" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>                            
    </div>
  </div>
   <div class="vx-row">
     <div class="vx-col sm:w-1/2 w-full">
      <div class="w-full mt-5">     
          <label class="text-sm">Course Studying or Studied</label>
          <v-select v-model="course" :options="courseList" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>                           
    </div> -->
    <div class="vx-col sm:w-1/2 w-full">
    <div class="w-full mt-5">     
      <label class="text-sm">Account Type</label>
      <v-select 
        v-model="studyStatus" 
        :options="studyStatusSelect" 
        label="value"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      />
    </div>                            
  </div>

  
  <div class="vx-row">
     <!-- <div class="vx-col sm:w-1/2 w-full">
      <div class="w-full mt-5">
         <label class="text-sm">Date of Birth</label>
         <flat-pickr v-model="birthday" class="w-full" />
         <datepicker :disabled-dates="disabledDates" placeholder="Select Date" v-model="birthday"></datepicker>
      </div>                           
    </div> -->
    <div class="vx-col mt-5 sm:w-1/2 w-full">
        <label class="text-sm mt-2">Gender</label>
        <div class="mt-2">
          <vs-radio v-model="gender" vs-value="male" class="mr-4">Male</vs-radio>
          <vs-radio v-model="gender" vs-value="female" class="mr-4">Female</vs-radio>
          <vs-radio v-model="gender" vs-value="other">Not wish to specify</vs-radio>
        </div>
     </div>
  </div>

<!-- <div class="vx-row">
    <div class="vx-col mt-5 sm:w-1/2">
      <div class="d-flex align-items-center">
        <label class="text-sm mt-2">Become verified user by adding university email address.</label>
        <span class="material-icons info ml-2" v-b-tooltip.hover.v-primary
              title=" 'Verified badge' will help you to get more preference among employers and recruiters in our community.">info</span>
      </div>
      <vs-input 
        ref="uniEmailInput" 
        class="w-full" 
        v-model="uniEmailState" 
        :disabled="isUniEmailVerify"></vs-input>
    </div>
    <div v-if="!isUniEmailVerify" class="vx-col sm:w-2/4 flex items-end mt-2 lg:mt-0">
      <vs-button @click="sendCode">Send Verification Code</vs-button>
    </div>
  </div>  

  <div class="vx-row">
    <template v-if="!isUniEmailVerify">
    <div class="vx-col mt-5 sm:w-1/2">
      <label class="text-sm mt-2">Enter your verification code to become verified user.</label>
      <vs-input class="w-full" v-model="uniEmailVerifyCode"></vs-input>
    </div>
    <div  class="vx-col sm:w-1/4 flex items-end mt-2 lg:mt-0">
      <vs-button class="" @click="verifyCode">Verify Now</vs-button>
    </div>
    </template>
    <div v-else class="vx-col mt-5 sm:w-1/2 flex items-end">
      <vs-alert active="true" color="success">Congrats, your account is verified!</vs-alert>
    </div>
  </div>  -->
  

  <vs-divider />
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-4">
      <vs-button @click="updateUserProfileBasedOnEmail" class="ml-auto mt-2" size="small" :disabled="!isFormValid">Save Changes</vs-button>
      <vs-button class="ml-4 mt-2" type="border" color="dark" size="small" @click="backToHomePage">Close</vs-button>
    </div>
  </vx-card>
</template>

<script>
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import { createSuccessObject, createErrorObject } from '@/utils'

export default {
  name: 'UserSettingInfo',
  components: {
    flatPickr,
    vSelect,
    Datepicker,
  },
  data () {
    return {
      disabledDates: {
        from: new Date()
      },
      displayName: this.$store.state.auth.userData.displayName,
      email: this.$store.state.auth.userData.email,
      university: {
        label: this.$store.state.auth.userData.universityName,
        value: this.$store.state.auth.userData.university
      },
      studyStatus: null, // This will hold the selected value
      studyStatusSelect: [
        { value: 'Meme Creator' },
        { value: 'Meme Collector' },
        { value: 'Meme Promoter' },
      ],
      updateAvatarImage: '',
      previewAvatarImage: { src: '' },
      about: this.$store.state.auth.userData.about,
      birthday: this.$store.state.auth.userData.birthday,
      gender: this.$store.state.auth.userData.gender.toLowerCase(),
      studyArea: {
        label: this.$store.state.auth.userData.studyAreaName,
        value: this.$store.state.auth.userData.studyArea
      },
      course: { 
        label: this.$store.state.auth.userData.courseName, 
        value: this.$store.state.auth.userData.course 
      },
      studyStatus: this.$store.state.auth.userData.studyStatus,
      uniEmailState: '',
      uniEmailVerifyCode: '',
      uniList: [],
      studyAreaList: [],
      courseList: [],
 
    }
  },
  computed: {
    userData () {
      return this.$store.state.auth.userData
    },
    isFormValid () {
      // return this.course.value !== '' && this.studyArea.value !== ''
      return true
    },
    isUniEmailVerify () {
      return this.$store.state.auth.userData.isUniEmailVerify
    },
    uniEmail: {
      get () {
        this.uniEmailState = this.$store.state.auth.userData.uniEmail
        return this.$store.state.auth.userData.uniEmail
      },
      set (val) {
        this.uniEmailState = val
      }
    }
  },
  watch: {
    university (newUniversity) {
      if (newUniversity !== '') {
        axios.post(`${process.env.VUE_APP_BASE_URL  }/StudyAreasofAnUniversity`, {
          univ_id : newUniversity.value
        })
          .then(res => {
            this.studyArea = ''
            this.studyAreaList = res.data.data.filter(studyArea => {
              if (studyArea.stu_area_id !== null) return studyArea
            }).map(sa => {
              return {
                label: sa.study_area, 
                value: sa.stu_area_id
             } 
            })
          })
          .catch(err => console.log(err))
      }    
    },
    studyArea (newStudyArea) {
      if (newStudyArea !== '') {
        this.getCourseList(this.university.value, newStudyArea.value)
      }
    },
    uniEmail (val) {
      this.uniEmailState = val
    }
  },
  mounted () {
    this.$store.dispatch('auth/fetchUniEmail')
  },
  // created () {
  //   this.getUniList()
  //   this.getStudyAreaList()
  // },
  methods: {
    uploadImage () {
      this.$refs.imageInputProfile.click()
    },
    onImageChange (e) {
      this.updateAvatarImage = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewAvatarImage) {
        return function (e) {
          previewAvatarImage.src = e.target.result
        }
      })(this.previewAvatarImage)
      fr.readAsDataURL(this.updateAvatarImage)
    },
    // getUniList () {
    //   axios.post(`${process.env.VUE_APP_BASE_URL  }/getinstitutelist`)
    //   .then(res => {
    //     this.uniList = res.data.data.map(uniRes => { return {
    //       label: uniRes.univ_name,
    //       value: uniRes.university_id
    //     }})
    //   }).catch(err => console.log(err))
    // },
    // getStudyAreaList () {
    //   axios.post(`${ process.env.VUE_APP_BASE_URL }/StudyAreasofAnUniversity`, {
    //     univ_id : this.university.value
    //   }).then(res => {          
    //     if (res.data.status === true) {
    //       this.studyAreaList = res.data.data.map(r => { 
    //         return { 
    //           label: r.study_area, 
    //           value: r.stu_area_id } 
    //         })
    //       this.getCourseList(this.university.value, this.studyArea.value)
    //     }
    //   })
    //     .catch(err => {
    //       if (process.env === 'development') {
    //         console.log(err)
    //       }
    //     })
    // },
    // getCourseList (universityId, studyAreaId) {
    //   axios.post(`${process.env.VUE_APP_BASE_URL}/getCoursesBasedOnUniAndStudyArea`, {
    //     univ_id: universityId,
    //     study_area: studyAreaId
    //   }).then(res => {          
    //     if (res.data.status === true) {
    //       this.courseList = res.data.data.map(r => {
    //         return {
    //           label: r.course_name, 
    //           value: r.course_id 
    //         }
    //       })
    //     }
    //   }).catch(err => console.log(err))
    // },
    updateUserProfileBasedOnEmail () {
      this.$vs.loading()
      this.$store.dispatch('auth/updateUserProfile', {
        displayName: this.displayName,
        university: this.university.value,
        universityName: this.university.label,
        studyArea: this.studyArea.value,
        studyAreaName: this.studyArea.label,
        course: this.course.value,
        courseName: this.course.label,
        studyStatus: this.studyStatus.value,
        email: this.email,
        updateAvatarImage: this.updateAvatarImage,
        birthday: new Date(this.birthday),
        gender: this.gender,
        notify: this.$vs.notify
      }).then(() => {
        this.$vs.loading.close()
      })
    },
    backToHomePage () {
      this.$router.push('/')
    },
    sendCode () {
      if (this.uniEmailState === '' || !this.uniEmailState.match(/^[a-zA-Z0-9\.\_\-]+@[a-zA-Z0-9\.\_\-]+(\.edu){1}(\.au){0,1}$/g)) {
        this.$vs.notify(createErrorObject('Invalid email address', 'Invalid university email address entered, please try again.'))
      } else {
        this.$vs.loading()
        this.errors.uniEmail = ''
        this.$store.dispatch('auth/sendUniEmailVerifyCode', {
          uniEmail: this.uniEmailState,
          notify: this.$vs.notify,
          loading: this.$vs.loading
        })
      }
    },
    verifyCode () {
      this.$store.dispatch('auth/verifyUniEmailCode', {
        verifyCode: this.uniEmailVerifyCode,
        uniEmail: this.uniEmailState,
        notify: this.$vs.notify
      })
    }
  }
}

</script>
<style scoped>
input[type="file"] {
  display: none;
}
.material-icons.info{
  color: rgb(96, 155, 252);
}

</style>
